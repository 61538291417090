import AllFormsList from "@/components/AllForms/allForms.component";
import InfoCaptureProvider from "@/contexts/InfoCaptureProvider";
import { UserContext } from "@/contexts/UserContext/userContext";
import BasePage from "@/infrastructure/basePage.component";
import { Card } from "@/shadcn/components/ui/card";
import { useContext } from "react";
const AllForms = () => {
  const { user } = useContext(UserContext);

  return (
    <InfoCaptureProvider>
      <BasePage>
        <Card className="flex flex-col w-full h-full overflow-hidden rounded-sm items-center justify-center">
          <AllFormsList />
        </Card>
      </BasePage>
    </InfoCaptureProvider>
  );
};

export default AllForms;
