import HomeAdmin from "@/components/HomeAdmin/homeAdmin.component";
import HomeUser from "@/components/HomeUser/homeUser.component";
import InfoCaptureProvider from "@/contexts/InfoCaptureProvider";
import { UserContext } from "@/contexts/UserContext/userContext";
import BasePage from "@/infrastructure/basePage.component";
import { Card } from "@/shadcn/components/ui/card";
import { useContext } from "react";
const HomePage = () => {
  const { user } = useContext(UserContext);
  return (
    <InfoCaptureProvider>
      <BasePage>
        <Card className="flex flex-col w-full h-full overflow-hidden rounded-sm items-center justify-center">
          {user.admin ? <HomeAdmin /> : <HomeUser />}
        </Card>
      </BasePage>
    </InfoCaptureProvider>
  );
};

export default HomePage;
