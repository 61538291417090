import { createContext, Dispatch } from "react";
import { InfoCaptureAction, InfoCaptureState } from "../types";

const initialState: InfoCaptureState = {
  data: null,
  loading: false,
  processingBatch: false,
  error: null,
  files: {}, // Add files to the initial state
  groupID: "", // Add groupID to the initial state
  stale: true, // Add stale to the initial state
  queueLength: 0,
};

const InfoCaptureContext = createContext<{
  state: InfoCaptureState;
  dispatch: Dispatch<InfoCaptureAction>;
  clearStale: () => Promise<void>;
}>({
  state: initialState,
  dispatch: () => null,
  clearStale: async () => {},
});

const infoCaptureReducer = (
  state: InfoCaptureState,
  action: InfoCaptureAction
): InfoCaptureState => {
  switch (action.type) {
    case "SET_DATA":
      return { ...state, data: action.payload };
    case "SET_LOADING":
      return { ...state, loading: action.payload };
    case "SET_ERROR":
      return { ...state, error: action.payload };
    case "LOG_INPUT":
      console.log("Form Input:", action.payload);
      return state;
    case "SET_FILES":
      console.log("Files:", action.payload);
      return { ...state, files: action.payload };
    case "SET_GROUPID":
      console.log("Group ID:", action.payload);
      return { ...state, groupID: action.payload };
    case "SET_STALE":
      return { ...state, stale: action.payload };
    case "SET_QUEUE_LENGTH":
      return { ...state, queueLength: action.payload };
    case "SET_PROCESSING_BATCH":
      return { ...state, processingBatch: action.payload };
    default:
      return state;
  }
};
export { InfoCaptureContext, infoCaptureReducer, initialState };
