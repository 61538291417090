import { getAdminExcelReport } from "@/models/reports.model";
import { Button } from "@/shadcn/components/ui/button";
import React, { useState } from "react";
import CustomTextLoader from "../CustomTextLoader/customTextLoader.componnet";
import SuperAdminReportsTableContainer from "../SuperAdminReportsTable/superAdminReportsTableContainer.component";

type HomeAdminProps = {
  // TODO: add props here
};

const HomeAdmin: React.FC<HomeAdminProps> = (props) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  async function handleDownloadReport() {
    setError("");
    setLoading(true);
    const resp = await getAdminExcelReport();
    setLoading(false);

    if (resp.rStatus === "error") {
      setError(resp.error);
      return;
    }

    // Download the file
    const url = window.URL.createObjectURL(new Blob([resp.rData]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "report.xlsx");
    document.body.appendChild(link);
    link.click();
  }

  return (
    <>
      <h1 className="text-3xl font-bold text-center text-primary mt-3">
        KD STRATEGIES ADMIN
      </h1>
      <div className="flex-grow flex items-center flex-col justify-center">
        {loading ? (
          <CustomTextLoader
            texts={["Collecting data", "Sorting data", "Generating report"]}
          />
        ) : (
          error && <div className="text-red-500">{error}</div>
        )}
        <Button
          className="mt-3 mb-6"
          onClick={handleDownloadReport}
          disabled={loading}
        >
          Download Excel Report
        </Button>
        <SuperAdminReportsTableContainer />
      </div>
    </>
  );
};

export default HomeAdmin;
