import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns"; // Import the format function from date-fns

import { SuperAdminReports } from "@/types";
import React from "react";
import { DataTable } from "../DataTable/dataTable.component";

type SuperAdminReportsTableProps = {
  reports: SuperAdminReports[];
};

const SuperAdminReportsTable: React.FC<SuperAdminReportsTableProps> = ({
  reports,
}) => {
  const columns: ColumnDef<SuperAdminReports>[] = [
    {
      id: "uuid",
      header: "UUID",
      accessorKey: "uuid",
    },
    {
      id: "name",
      header: "Name",
      accessorKey: "name",
    },
    {
      id: "surname",
      header: "Surname",
      accessorKey: "surname",
    },
    {
      id: "nationality",
      header: "Nationality",
      accessorKey: "nationality",
    },
    {
      id: "created_at",
      header: "Uploaded",
      accessorKey: "created_at",
      cell: ({ row }) => {
        // Format the created_at date and time for display in "dd/MM/yyyy HH:mm" format
        const formattedTime = format(
          new Date(row.original.created_at || ""),
          "dd/MM/yyyy HH:mm" // Use "dd/MM/yyyy HH:mm" to match the desired format
        );
        return formattedTime;
      },
    },
    {
      id: "uploader",
      header: "Uploaded By",
      accessorKey: "uploader",
    },
  ];

  return <DataTable columns={columns} data={reports} />;
};

export default SuperAdminReportsTable;
