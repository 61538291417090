import { apiFetcher } from "@/services/API.service";
import { StoredDocument } from "@/types";

// Upload to BE
export async function uploadDocument(doc: StoredDocument): Promise<
  | {
      rStatus: "success";
      rData: "Document uploaded successfully";
    }
  | {
      rStatus: "error";
      error: string;
    }
> {
  try {
    const response = await apiFetcher({
      route: "/upload/image",
      method: "POST",
      options: {
        body: {
          image: doc.documentB64,
          uuid: doc.uuidGroup,
        },
      },
    });

    if (
      response.status === 200 ||
      response.status === 204 ||
      response.status === 202
    ) {
      return { rStatus: "success", rData: "Document uploaded successfully" };
    }

    if (response.status === 0) {
      return {
        rStatus: "error",
        error: "Network error. Please try again later",
      };
    }

    return {
      rStatus: "error",
      error: "Incorrect response. Please contact support",
    };
  } catch (error) {
    console.error("uploadDocument:", error);
    return {
      rStatus: "error",
      error: "Failed to upload document",
    };
  }
}
