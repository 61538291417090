import { UserInfo, UserInfoSchema } from "@/types";
import { validateWithSchema } from "@/utils/dataValidators.zod";
import { getTokensLocalStorage } from "@/utils/tokenHandler.util";
import { jwtDecode, JwtPayload } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { defaultUserContext, UserContext } from "./userContext";

type UserProviderProps = {
  children: React.ReactNode;
};

// UserProvider component to manage and provide user state
const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<UserInfo>(defaultUserContext.user);

  async function getUserDetails() {
    const token = await getTokensLocalStorage();
    if (!token || !token.refreshToken) {
      return;
    }
    const decoded: JwtPayload = jwtDecode(token.refreshToken);

    const validData = validateWithSchema(
      decoded,
      UserInfoSchema,
      "UserInfoSchema"
    );

    if (!validData) {
      console.error("Invalid user data");
      console.log(decoded);
      return;
    }

    setUser(validData);
  }

  useEffect(() => {
    getUserDetails();
  }, []);

  return !user.username ? null : (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
