import { createContext } from "react";

export interface NetworkStatusContextType {
  isOnline: boolean;
}

// Create a context with a default value
export const NetworkStatusContext = createContext<NetworkStatusContextType>({
  isOnline: false, // Default value
});
