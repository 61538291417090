import { ROUTE_HOME } from "@/constants/routes.constants";
import { sendLoginRequest } from "@/models/auth.model";
import { Button } from "@/shadcn/components/ui/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shadcn/components/ui/card";
import { Input } from "@/shadcn/components/ui/input";
import {
  checkLockStoreUserDetails,
  hashPassword,
  setLocStoreIsLoggedIn,
  setLocStoreUserDetails,
} from "@/utils/localAuth.util"; // Import hashPassword function
import React, { useState } from "react";

type LoginProps = {
  // TODO: add props here
};

const Login: React.FC<LoginProps> = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);

  async function handleLogin() {
    if (loggingIn) return;
    if (username === "" || password === "") {
      setError("Please enter username and password");
      return;
    }
    setError("");
    setLoggingIn(true);
    const lsResult = checkLockStoreUserDetails(username, password);
    console.log(lsResult);
    if (lsResult === "no-ls") {
      console.log("No user details found");
      //  make request to BE
      await handleLoginBackend();
      return;
    }

    if (lsResult === "user-not-found") {
      console.log("User not found");
      //  make request to BE
      await handleLoginBackend();
      return;
    }

    if (lsResult === "invalid-password") {
      console.log("Invalid password");
      //  make request to BE
      await handleLoginBackend();
      return;
    }

    if (lsResult === "success") {
      console.log("Login successful");
      setLocStoreIsLoggedIn();
      // go to home page
      window.location.href = ROUTE_HOME;
      return;
    }
  }

  async function handleLoginBackend() {
    // make request to BE
    const res = await sendLoginRequest(
      username,
      hashPassword(password) // Hash the password before sending it to the backend
    );
    console.log(
      "<<<<<<<<<<<<<<<<<<<<<<<<<<<< handleLoginBackend >>>>>>>>>>>>>>>>>>>>>>>>>>>>>"
    );
    console.log(res);

    if (res.rStatus === "success") {
      // if login is successful
      setLocStoreUserDetails(username, password);
      setLocStoreIsLoggedIn();
      // go to home page
      window.location.href = ROUTE_HOME;
      return;
    }
    setLoggingIn(false);
    setError(res.error);
  }

  return (
    <Card className="bg-white bg-opacity-80 border-none shadow-lg max-w-lg m-auto p-3 px-6 md:px-12 rounded-sm relative">
      <CardHeader>
        <CardTitle className="text-center font-bold text-2xl">Login</CardTitle>
      </CardHeader>
      <CardContent className="m-auto text-center">
        <div className="grid gap-2 w-full">
          <div className="grid grid-cols-2 items-center">
            <label className="font-semibold">Username</label>
            <Input
              type="text"
              className="form-control  p-1 ps-2 w-full"
              id="username"
              placeholder="Enter username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              autoCapitalize="off"
            />
          </div>
          <div className="grid grid-cols-2 items-center">
            <label className="font-semibold">Password</label>
            <Input
              type="password"
              className="form-control  p-1 ps-2  w-full"
              id="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              // on enter key press login
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleLogin();
                }
              }}
            />
          </div>
        </div>
        {
          // Display error message if there is any
          error && <div className="text-red-500 mt-5">{error}</div>
        }
        <Button
          onClick={handleLogin}
          className="mt-5"
          disabled={username === "" || password === "" || loggingIn}
        >
          {loggingIn ? "Logging in..." : "Login"}
        </Button>
      </CardContent>
    </Card>
  );
};

export default Login;
