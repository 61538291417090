import FileInput from "@/components/FileInput/fileInput.component";
import { InfoCaptureContext } from "@/contexts/InfoCaptureProvider";
import { useContext } from "react";

import React from "react";

const HomeUser: React.FC = () => {
  const { state, clearStale } = useContext(InfoCaptureContext);

  return (
    <>
      <div className="flex flex-col items-center mt-3">
        <h1 className="text-3xl font-bold text-center text-primary">
          KD STRATEGIES
        </h1>
        {state.groupID && (
          <span className="text-xs ml-1">
            Current Session: {state.groupID.split("-")[0]}
          </span>
        )}
        {state.queueLength > 0 && (
          <span className="text-xs ml-1">
            Items in Queue: {state.queueLength}
          </span>
        )}
      </div>

      {!state.groupID && (
        <div className="h-full p-5 text-center">
          To get started, click on the "New Session" button below.
        </div>
      )}

      <div className="flex-grow flex items-center flex-col justify-center w-full">
        {state.groupID && <FileInput />}
      </div>
      {/* <div className="flex-grow flex items-center flex-col justify-center w-full">
          <InfoCapture />
        </div> */}
    </>
  );
};

export default HomeUser;
