import { apiFetcher } from "@/services/API.service";
import { CaptureInfo, captureInfoSchema } from "@/types";
import { validateWithSchema } from "@/utils/dataValidators.zod";

export async function fetchFormFromUUID(uuid: string): Promise<
  | {
      rStatus: "success";
      rData: CaptureInfo | null;
    }
  | {
      rStatus: "error";
      error: string;
    }
> {
  try {
    const response = await apiFetcher({
      route: `/form/get`,
      method: "POST",
      options: {
        body: {
          uuid,
        },
      },
    });

    if (response.status === 200) {
      const validData = validateWithSchema(
        response.data,
        captureInfoSchema,
        "CaptureInfo"
      );

      if (!validData) {
        return { rStatus: "error", error: "Invalid response data" };
      }

      return { rStatus: "success", rData: validData };
    }

    if (response.status === 204) {
      return { rStatus: "success", rData: null };
    }

    if (response.status === 0) {
      return {
        rStatus: "error",
        error: "Network error. Please try again later",
      };
    }

    if (response.status === 500) {
      return {
        rStatus: "error",
        error: "Internal server error. Please try again later",
      };
    }

    return {
      rStatus: "error",
      error: "Incorrect response. Please contact support",
    };
  } catch (error) {
    console.error("fetchFormFromUUID:", error);
    return {
      rStatus: "error",
      error: "Failed to fetch form",
    };
  }
}

export async function sendSubmitForm(data: CaptureInfo): Promise<
  | {
      rStatus: "success";
    }
  | {
      rStatus: "error";
      error: string;
    }
> {
  try {
    const response = await apiFetcher({
      route: `/form/update`,
      method: "POST",
      options: {
        body: { info: data },
      },
    });

    if (response.status === 200 || response.status === 202) {
      return { rStatus: "success" };
    }

    if (response.status === 0) {
      return {
        rStatus: "error",
        error: "Network error. Please try again later",
      };
    }

    if (response.status === 500) {
      return {
        rStatus: "error",
        error: "Internal server error. Please try again later",
      };
    }

    return {
      rStatus: "error",
      error: "Incorrect response. Please contact support",
    };
  } catch (error) {
    console.error("submitForm:", error);
    return {
      rStatus: "error",
      error: "Failed to submit form",
    };
  }
}
