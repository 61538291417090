import { apiFetcher, tokenFetcher } from "@/services/API.service";

import { ROUTE_LOGIN_OUT } from "@/constants/routes.constants";
import { FetchResponse, JWT } from "@/types";
import { validateWithSchema } from "@/utils/dataValidators.zod";
import { setTokensLocalStorage } from "@/utils/tokenHandler.util";
import { z } from "zod";

// We need to define the type of the data we expect to receive from the API
export type Tokens = {
  refreshTokenResponse: {
    refreshToken: string;
  };
};

export type MfaTokens = {
  mfaConfirmResponse: {
    refreshToken: string;
  };
};

// Give BE RefreshToken, get back JWT (R + Auth)
export async function fetchJwt(refreshToken: JWT): Promise<FetchResponse> {
  const response = await tokenFetcher({
    route: "/auth/refreshToken",
    method: "POST",
    options: { body: refreshToken },
  });

  if (response.status === 200) {
    // console.log("Refresh token successful");
    return response;
  } else {
    console.log("Refresh token failed");
    window.location.href = ROUTE_LOGIN_OUT;
    return response;
  }
}

// Give BE encoded Email, Pw, Code, get back JWT (R + Auth)
export async function mfaLogin(
  encodedMFAConfirmString: string
): Promise<FetchResponse> {
  console.log("HIT REQUEST CONFIRM MFA");
  console.log(encodedMFAConfirmString, "ENCODED STRING");

  const response = await tokenFetcher({
    route: "/auth/mfa/login",
    method: "POST",
    options: { body: { encodedMFAConfirmString } },
  });

  console.log(response, "RESPONSE");
  if (response.status === 200) {
    console.log("MFA CONFIRM sent");
    return response;
  } else {
    console.log("MFA CONFIRM Failed");
    return response;
  }
}

// Alive checker
export async function checkAlive(): Promise<boolean> {
  const response = await apiFetcher({
    route: "/online",
    method: "GET",
    excludeToken: true,
  });

  if (response.status === 204) {
    return true;
  } else {
    console.log("Alive check failed");
    return false;
  }
}

const LoginRequestResponseSchema = z.object({
  refreshToken: z.string(),
});

type LoginRequestResponse = z.infer<typeof LoginRequestResponseSchema>;

export async function sendLoginRequest(
  username: string,
  password: string
): Promise<{ rStatus: "success" } | { rStatus: "error"; error: string }> {
  try {
    const response = await tokenFetcher({
      route: "/login",
      method: "POST",
      options: { body: { username, password } },
    });
    if (response.status === 200) {
      const validData = validateWithSchema(
        response.data,
        LoginRequestResponseSchema,
        "LoginRequestResponse"
      );
      if (validData) {
        setTokensLocalStorage(response);
        return { rStatus: "success" };
      } else {
        return { rStatus: "error", error: "Invalid response data" };
      }
    }

    if (response.status === 0) {
      return {
        rStatus: "error",
        error: "Network error. Please try again later",
      };
    }

    return { rStatus: "error", error: "Invalid username or password" };
  } catch (error) {
    console.error("sendLoginRequest:", error);
    return {
      rStatus: "error",
      error: "An error occurred. Please try again later",
    };
  }
}
