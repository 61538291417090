import { Button } from "@/shadcn/components/ui/button";
import { X } from "lucide-react";
import React, { useEffect, useState } from "react";

type PWAPromptProps = {
  // TODO: add props here
};

// Define the BeforeInstallPromptEvent interface to avoid TypeScript errors
interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: "accepted" | "dismissed" }>;
}

const PWAPrompt: React.FC<PWAPromptProps> = (props) => {
  const [deferredPrompt, setDeferredPrompt] =
    useState<BeforeInstallPromptEvent | null>(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handler = (e: Event) => {
      // Cast the event to BeforeInstallPromptEvent to access its properties
      const promptEvent = e as BeforeInstallPromptEvent;
      // Prevent the mini-infobar from appearing on mobile
      promptEvent.preventDefault();
      // Save the event so it can be triggered later
      setDeferredPrompt(promptEvent);
    };

    window.addEventListener("beforeinstallprompt", handler);

    // Check if the user is on a mobile device
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      // Show the install prompt
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };

  if (!deferredPrompt || !isMobile) {
    return null;
  }

  return (
    <div className="pwa-prompt w-full fixed top-3 left-0 z-50">
      <div className="bg-white m-3 p-3 rounded-md  text-center relative border shadow-md">
        {/* close  */}
        <button
          className="absolute top-1 right-1"
          onClick={() => setDeferredPrompt(null)}
        >
          <X size={20} />
        </button>
        <p className="text-balance mb-2">
          Install this app on your device for a better experience.
        </p>
        <Button onClick={handleInstallClick}>Install</Button>
      </div>
    </div>
  );
};

export default PWAPrompt;
