import { UserInfo } from "@/types";
import { createContext } from "react";

export type UserContextType = {
  user: UserInfo;
};

// Default State for the context
export const defaultUserContext: UserContextType = {
  user: {
    username: "",
    admin: false,
  },
};

// Create the context
export const UserContext = createContext<UserContextType>(defaultUserContext);
