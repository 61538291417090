import { checkAlive } from "@/models/auth.model";
import React, { ReactNode, useEffect, useState } from "react";
import { NetworkStatusContext } from "./NetworkStatusContext";

interface NetworkStatusProviderProps {
  children: ReactNode;
}

export const NetworkStatusProvider: React.FC<NetworkStatusProviderProps> = ({
  children,
}) => {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => {
      if (!navigator.onLine) {
        setIsOnline(false);
      }
      if (navigator.onLine) {
        console.log("Internet is back online");
      } else {
        console.log("Internet is offline");
      }
    };

    // Add event listeners for network status changes
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    // Cleanup event listeners on unmount
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(async () => {
      const isOnlinePromise = checkAlive();
      const timeoutPromise = new Promise<boolean>((resolve) =>
        setTimeout(() => resolve(false), 30000)
      );

      const isOnline = await Promise.race([isOnlinePromise, timeoutPromise]);

      if (!isOnline) {
        console.log("Network API is down");
        setIsOnline(false);
      } else {
        console.log("Network API is up");
        setIsOnline(true);
      }
    }, 15000);

    // Cleanup interval on unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <NetworkStatusContext.Provider value={{ isOnline }}>
      {children}
    </NetworkStatusContext.Provider>
  );
};
