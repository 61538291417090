import imageCompression from "browser-image-compression";

const compressAndConvert = async (file: File): Promise<string | null> => {
  const compressedFile = await imageCompression(file, {
    maxWidthOrHeight: 800,
    useWebWorker: true,
  });
  return convertToPNG(compressedFile); // Use Canvas or other methods
};

const convertToPNG = async (file: File): Promise<string | null> => {
  try {
    // Check if the file is already a PNG
    if (file.type === "image/png") {
      const reader = new FileReader();
      return await new Promise<string>((resolve, reject) => {
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = () => reject("File reading failed");
        reader.readAsDataURL(file);
      });
    }

    const reader = new FileReader();
    const result = await new Promise<string>((resolve, reject) => {
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject("File reading failed");
      reader.readAsDataURL(file);
    });

    const img = new Image();
    const imgLoadPromise = new Promise<void>((resolve, reject) => {
      img.onload = () => resolve();
      img.onerror = () => reject("Failed to load image");
    });
    img.src = result;
    await imgLoadPromise;

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx) throw new Error("Canvas context not supported");

    canvas.width = img.width;
    canvas.height = img.height;
    ctx.drawImage(img, 0, 0);

    // Convert to PNG Base64 string
    return canvas.toDataURL("image/png");
  } catch (error) {
    console.error(error);
    return null;
  }
};

export { compressAndConvert, convertToPNG };
