import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import InfoCapture from "@/components/InfoCapture/infoCapture.component";
import InfoCaptureProvider from "@/contexts/InfoCaptureProvider";
import BasePage from "@/infrastructure/basePage.component";
import { fetchFormFromUUID } from "@/models/form.model";
import { Card } from "@/shadcn/components/ui/card";
import { CaptureInfo } from "@/types";

type FormPageProps = {
  // TODO: add props here
};

const FormPage: React.FC<FormPageProps> = (props) => {
  const { uuid } = useParams();
  const [formData, setFormData] = useState<CaptureInfo | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    async function fetchFormData() {
      if (!uuid) {
        return;
      }

      setLoading(true);
      setError("");
      const res = await fetchFormFromUUID(uuid);
      setLoading(false);

      if (res.rStatus === "error") {
        setError(res.error);
        return;
      }

      setFormData(res.rData);
    }

    fetchFormData();
  }, [uuid]);

  return (
    <InfoCaptureProvider>
      <BasePage>
        <Card className="flex flex-col w-full h-full overflow-hidden rounded-sm items-center justify-center">
          <div className="mt-3 mb-2 font-bold">UUID: {uuid}</div>
          {loading ? (
            <div>Fetching data...</div>
          ) : error ? (
            <div className="text-red-500">{error}</div>
          ) : (
            <InfoCapture formData={formData} />
          )}
        </Card>
      </BasePage>
    </InfoCaptureProvider>
  );
};

export default FormPage;
