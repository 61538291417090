import CryptoJS from "crypto-js";

type LoginDetails = {
  username: string;
  password: string;
};

const LS_USER_DETAILS = "userDetails";
const LS_IS_LOGGED_IN = "isLoggedIn";

// Encode login details to base64
export function encodeLoginDetails(loginDetails: LoginDetails): string {
  return btoa(JSON.stringify(loginDetails));
}

// Decode login details from base64
export function decodeLoginDetails(encodedLoginDetails: string): LoginDetails {
  try {
    return JSON.parse(atob(encodedLoginDetails));
  } catch (error) {
    console.error(`decodeLoginDetails: ${error}`);
    return { username: "", password: "" };
  }
}

// Hash the password using MD5
export function hashPassword(password: string): string {
  // Using CryptoJS MD5 for hashing the password
  return CryptoJS.MD5(password).toString();
}

// Verify the hashed password
export function verifyPassword(
  password: string,
  hashedPassword: string
): boolean {
  // Comparing the hashed password with the provided password
  return hashPassword(password) === hashedPassword;
}

export function setLocStoreUserDetails(
  username: string,
  password: string
): void {
  console.log("Setting user details");
  const hashedPassword = hashPassword(password);
  console.log("Hashed password:", hashedPassword);

  // Store the username and password in local storage
  const userDetails = encodeLoginDetails({
    username,
    password: hashedPassword,
  });
  localStorage.setItem(LS_USER_DETAILS, userDetails);
  console.log("User details stored successfully");
}

export function getLocStoreUserDetails(): string | null {
  const userDetails = localStorage.getItem(LS_USER_DETAILS);
  if (!userDetails) {
    return null;
  }

  return userDetails;
}

export function checkLockStoreUserDetails(
  username: string,
  password: string
): "no-ls" | "user-not-found" | "invalid-password" | "success" {
  // Check if the username and password are valid
  const userDetails = getLocStoreUserDetails();
  if (!userDetails) {
    return "no-ls";
  }

  const { username: storedUsername, password: storedPassword } =
    decodeLoginDetails(userDetails);

  if (username !== storedUsername) {
    return "user-not-found";
  }

  if (!verifyPassword(password, storedPassword)) {
    return "invalid-password";
  }

  return "success";
}

export function setLocStoreIsLoggedIn(): void {
  localStorage.setItem(LS_IS_LOGGED_IN, "true");
}

export function getLocStoreIsLoggedIn(): string | null {
  return localStorage.getItem(LS_IS_LOGGED_IN);
}

export function removeLocStoreIsLoggedIn(): void {
  localStorage.removeItem(LS_IS_LOGGED_IN);
}

export function removeLocStoreUserDetails(): void {
  localStorage.removeItem(LS_USER_DETAILS);
}
