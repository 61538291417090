import { getAdminReports } from "@/models/reports.model";
import { SuperAdminReports } from "@/types";
import React, { useEffect, useState } from "react";
import CustomTextLoader from "../CustomTextLoader/customTextLoader.componnet";
import SuperAdminReportsTable from "./superAdminReportsTable.component";

type SuperAdminReportsTableContainerProps = {
  // TODO: add props here
};

const SuperAdminReportsTableContainer: React.FC<
  SuperAdminReportsTableContainerProps
> = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [reports, setReports] = useState<SuperAdminReports[]>([]);

  useEffect(() => {
    async function fetchReports() {
      setLoading(true);
      setError("");
      const resp = await getAdminReports();
      setLoading(false);

      if (resp.rStatus === "error") {
        setError(resp.error);
        return;
      }

      setReports(
        resp.rData
          // sort by created_at, is a string, convert to date
          .sort((a, b) => {
            return (
              new Date(b.created_at ?? "").getTime() -
              new Date(a.created_at ?? "").getTime()
            );
          })
      );
    }

    fetchReports();
  }, []);

  return loading ? (
    <CustomTextLoader
      texts={["Collecting data", "Sorting data", "Generating report"]}
    />
  ) : error ? (
    <div>Error: {error}</div>
  ) : reports.length === 0 ? (
    <div>No reports available</div>
  ) : (
    <SuperAdminReportsTable reports={reports} />
  );
};

export default SuperAdminReportsTableContainer;
