import { apiFetcher } from "@/services/API.service";
import { DocList, docListSchema } from "@/types";
import { validateArrayWithSchema } from "@/utils/dataValidators.zod";

export async function fetchForms(): Promise<
  | {
      rStatus: "success";
      rData: DocList[];
    }
  | {
      rStatus: "error";
      error: string;
    }
> {
  try {
    const response = await apiFetcher({
      route: "/form/user",
      method: "POST",
    });

    if (
      response.status === 200 ||
      response.status === 204 ||
      response.status === 202
    ) {
      if (response.status === 200) {
        const validData = validateArrayWithSchema(
          response.data,
          docListSchema,
          "DocList"
        );

        if (!validData) {
          return { rStatus: "error", error: "Invalid response data" };
        }

        return { rStatus: "success", rData: validData };
      }
    }

    if (response.status === 0) {
      return {
        rStatus: "error",
        error: "Network error. Please try again later",
      };
    }

    return {
      rStatus: "error",
      error: "Incorrect response. Please contact support",
    };
  } catch (error) {
    console.error("uploadDocument:", error);
    return {
      rStatus: "error",
      error: "Failed to upload document",
    };
  }
}
