import { uploadDocument } from "@/models/upload.model";
import { toast } from "@/shadcn/hooks/use-toast";
import { StoredDocument } from "@/types";
import localforage from "localforage";

localforage.config({
  name: "DocumentApp",
  storeName: "document_store",
});

let isProcessing = false; // Flag to indicate if a document is being processed

// Synchronize documents in the queue, happens when app is online
export const syncDocuments = async () => {
  if (isProcessing) {
    console.log("A document is already being processed.");
    return false;
  }

  const queue = await getDocumentQueue();

  if (queue.length === 0) {
    console.log("No documents to synchronize.");
    return false;
  }

  console.log(`Found ${queue.length} documents. Starting upload...`);

  const failedDocuments: StoredDocument[] = [];
  const staleDocuments: StoredDocument[] = [];

  toast({
    title: "Batch Processing",
    description: "Synchronizing documents...",
    duration: 3000,
  });
  for (const doc of queue) {
    try {
      isProcessing = true; // Set the flag to indicate processing has started
      const rStatus = await processDocumentForUpload(doc); // Upload each document

      if (rStatus === "success") {
        console.log(`Document ${doc.documentName} processed successfully.`);
        await removeDocumentFromQueue(doc.id);
        toast({
          title: "Success",
          description: `Document ${doc.documentName} processed successfully.`,
          duration: 3000,
        });
      } else {
        if (doc.status === "failed") {
          doc.status = "stale";
          staleDocuments.push(doc); // Keep track of stale documents
        } else {
          doc.status = "failed";
          failedDocuments.push(doc); // Keep track of failed documents
        }
      }
    } catch (error) {
      console.error(`Failed to upload document ${doc.id}:`, error);

      if (doc.status === "failed") {
        doc.status = "stale";
        staleDocuments.push(doc); // Keep track of stale documents
      } else {
        doc.status = "failed";
        failedDocuments.push(doc); // Keep track of failed documents
      }
    } finally {
      isProcessing = false; // Clear the flag when processing is complete
    }
  }

  // Update the queue to include only stale documents
  addToStaleQueue(staleDocuments);

  // Update the queue to include only failed documents
  await updateDocumentQueue(failedDocuments);
  console.log("Synchronization complete.");
};

// Add document to queue
// Input: doc (StoredDocument) - The document to be added to the queue
export const addDocumentToQueue = async (
  doc: StoredDocument
): Promise<boolean> => {
  try {
    const queue =
      (await localforage.getItem<StoredDocument[]>("documentQueue")) || [];
    queue.push(doc);
    await localforage.setItem("documentQueue", queue);
    const updatedQueue = await localforage.getItem<StoredDocument[]>(
      "documentQueue"
    );
    console.log("Current queue after push:", updatedQueue);
    const isDocInQueue = updatedQueue
      ? updatedQueue.some((d) => d.id === doc.id)
      : false;
    console.log(`Document ${doc.id} is in queue:`, isDocInQueue);
    return isDocInQueue;
  } catch (error) {
    console.error("Failed to add document to queue:", error);
    return false;
  }
};

// Check if document is in the queue
// Input: doc (StoredDocument.id) - The document to check
// Output: Promise<boolean> - True if document is in the queue
export const isDocumentInQueue = async (docID: string): Promise<boolean> => {
  const queue = await getDocumentQueue();
  return queue.some((doc) => doc.id === docID);
};

// Remove document from queue
// Input: docID (string) - The document to be removed from the queue
// Output: Promise<boolean> - True if document is removed from the queue
export const removeDocumentFromQueue = async (
  docID: string
): Promise<boolean> => {
  try {
    const queue =
      (await localforage.getItem<StoredDocument[]>("documentQueue")) || [];
    const updatedQueue = queue.filter((doc) => doc.id !== docID);
    await localforage.setItem("documentQueue", updatedQueue);
    const isDocInQueue = updatedQueue.some((d) => d.id === docID);
    console.log(`Document ${docID} is in queue:`, isDocInQueue);
    return !isDocInQueue;
  } catch (error) {
    console.error("Failed to remove document from queue:", error);
    return false;
  }
};

// Get queued documents, happens when app is online
// Output: Promise<StoredDocument[]> - The list of documents in the queue
export const getDocumentQueue = async (): Promise<StoredDocument[]> => {
  return (await localforage.getItem<StoredDocument[]>("documentQueue")) || [];
};

// Update the queue with only failed documents
// Input: failedDocs (StoredDocument[]) - The documents that failed to upload
// Output: Promise<void>
export const updateDocumentQueue = async (
  failedDocs: StoredDocument[]
): Promise<void> => {
  await localforage.setItem("documentQueue", failedDocs);
};

// Add to stale queue
// Input: doc (StoredDocument[]) - The document to be added to the stale queue
// Output: Promise<boolean> - True if document is in the stale queue
export const addToStaleQueue = async (
  doc: StoredDocument[]
): Promise<boolean> => {
  try {
    const staleQueue =
      (await localforage.getItem<StoredDocument[]>("staleQueue")) || [];
    staleQueue.push(...doc);
    await localforage.setItem("staleQueue", staleQueue);
    return staleQueue.includes(doc[0]);
  } catch (error) {
    console.error("Failed to add document to stale queue:", error);
    return false;
  }
};

// Get stale queue
// Output: Promise<StoredDocument[]> - The list of stale documents
export const getStaleQueue = async (): Promise<StoredDocument[]> => {
  return (await localforage.getItem<StoredDocument[]>("staleQueue")) || [];
};

// Clear stale queue
// Output: Promise<void>
export const clearStaleQueue = async (): Promise<void> => {
  await localforage.removeItem("staleQueue");
};

// Process document for upload based on function name
// Input: doc (StoredDocument) - The document to be processed
// Output: Promise<void>
const processDocumentForUpload = async (doc: StoredDocument) => {
  switch (doc.functionName) {
    case "uploadDocument": {
      const res = await uploadDocument(doc);
      return res.rStatus;
    }
    case "loadDocument":
      console.log(`Document ${JSON.stringify(doc)} loaded successfully.`);
      toast({
        title: "Success",
        description: "Document Batch Uploaded",
        duration: 3000,
      });
      return "success";
    default:
      throw new Error(`Unknown function: ${doc.functionName}`);
  }
};
