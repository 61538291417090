import Swal from "sweetalert2";
import { ZodIssue, ZodTypeAny } from "zod";

function isArray(value: unknown): value is Array<unknown> {
  if (Array.isArray(value)) {
    return true;
  }
  return false;
}

// gets an array of zod errors and returns a string
function createErrorString(errors: ZodIssue[]): string {
  const fullErrorString: string[] = [];

  for (let i = 0; i < errors.length; i++) {
    const field = errors[i].path.join(".");
    const message = errors[i].message;
    const constructedStringError = `${field} - ${message}`;
    fullErrorString.push(constructedStringError);
  }

  return fullErrorString.join("<br>");
}

function fireTypeErrorMessage(errors: ZodIssue[], tsType: string) {
  const fullErrorString = createErrorString(errors);

  Swal.fire({
    icon: "error",
    title: `Invalid ${tsType}`,
    html: fullErrorString,
    showConfirmButton: true,
    confirmButtonColor: "#3085d6",
  });
}

export function validateWithSchema<T extends ZodTypeAny>(
  obj: unknown,
  schema: T,
  typeName: string
): NoInfer<T>["_output"] | false {
  const result = schema.safeParse(obj);
  if (result.success) {
    // Return the transformed data if validation is successful
    return result.data;
  }
  console.log(result.error.errors);

  fireTypeErrorMessage(result.error.errors, typeName);

  return false;
}

export function validateArrayWithSchema<T extends ZodTypeAny>(
  arr: unknown,
  schema: T,
  typeName: string
): NoInfer<T>["_output"][] | false {
  if (!isArray(arr)) {
    console.error(`Invalid ${typeName}`, arr);
    Swal.fire({
      icon: "error",
      title: `Invalid ${typeName}`,
      text: `Expected array, got ${typeof arr}`,
    });
    return false;
  }

  const retResult: NoInfer<T>["_output"][] = [];

  for (const obj of arr) {
    const result = schema.safeParse(obj);
    if (!result.success) {
      console.log(result.error.errors);
      fireTypeErrorMessage(result.error.errors, typeName);
      return false;
    }
    retResult.push(result.data);
  }
  return retResult;
}
