import { apiFetcher } from "@/services/API.service";
import { SuperAdminReports, SuperAdminReportsSchema } from "@/types";
import {
  validateArrayWithSchema,
  validateWithSchema,
} from "@/utils/dataValidators.zod";
import { z } from "zod";

const reportSchema = z.object({
  xlsx: z.string(),
});

function base64ToBlob(base64: string, contentType: string): Blob {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: contentType });
}

export async function getAdminExcelReport(): Promise<
  | {
      rStatus: "success";
      rData: File;
    }
  | {
      rStatus: "error";
      error: string;
    }
> {
  try {
    const response = await apiFetcher({
      route: "/admin/report/export",
      method: "POST",
    });

    if (response.status === 200) {
      const validData = validateWithSchema(
        response.data,
        reportSchema,
        "AdminExcelReport"
      );

      if (!validData) {
        return { rStatus: "error", error: "Invalid response data" };
      }

      try {
        // Convert base64 string to Blob
        const blob = base64ToBlob(
          validData.xlsx,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        const file = new File([blob], "report.xlsx", {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        return { rStatus: "success", rData: file };
      } catch (error) {
        console.error("getAdminExcelReport: ", error);
        return { rStatus: "error", error: "Could not create file." };
      }
    }

    if (response.status === 0) {
      return {
        rStatus: "error",
        error: "Network error. Please try again later",
      };
    }

    if (response.status === 500) {
      return {
        rStatus: "error",
        error: "Internal server error. Please try again later",
      };
    }

    return {
      rStatus: "error",
      error: "Incorrect response. Please contact support",
    };
  } catch (error) {
    console.error("getAdminExcelReport:", error);
    return {
      rStatus: "error",
      error: "An error occurred. Please try again later",
    };
  }
}

export async function getAdminReports(): Promise<
  | {
      rStatus: "success";
      rData: SuperAdminReports[];
    }
  | {
      rStatus: "error";
      error: string;
    }
> {
  try {
    const response = await apiFetcher({
      route: "/form/get/all",
      method: "POST",
    });

    if (response.status === 200) {
      const validData = validateArrayWithSchema(
        response.data,
        SuperAdminReportsSchema,
        "AdminReports"
      );

      if (!validData) {
        return { rStatus: "error", error: "Invalid response data" };
      }

      return { rStatus: "success", rData: validData };
    }

    if (response.status === 204) {
      return { rStatus: "success", rData: [] };
    }

    if (response.status === 0) {
      return {
        rStatus: "error",
        error: "Network error. Please try again later",
      };
    }

    if (response.status === 500) {
      return {
        rStatus: "error",
        error: "Internal server error. Please try again later",
      };
    }

    return {
      rStatus: "error",
      error: "Incorrect response. Please contact support",
    };
  } catch (error) {
    console.error("getAdminReposts:", error);
    return {
      rStatus: "error",
      error: "An error occurred. Please try again later",
    };
  }
}
