import { ROUTE_HOME } from "@/constants/routes.constants";
import BasePage from "@/infrastructure/basePage.component";
import { Button } from "@/shadcn/components/ui/button";
import { Card } from "@/shadcn/components/ui/card";
import React from "react";
import { useNavigate } from "react-router";

const PageNotFound: React.FC = () => {
  const nav = useNavigate();
  return (
    <BasePage>
      <Card className="flex flex-col w-full h-full rounded-sm dark:bg-background">
        <div className="flex w-full h-full justify-center items-center">
          <div className="text-center mb-3">
            <h1 className="text-3xl font-bold">Page not found</h1>
            <Button className="mt-3" onClick={() => nav(ROUTE_HOME)}>
              Go Home
            </Button>
          </div>
        </div>
      </Card>
    </BasePage>
  );
};

export default PageNotFound;
