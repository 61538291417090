import React, { useEffect, useState } from "react";

type CustomTextLoaderProps = { texts: string[]; className?: string };

const CustomTextLoader: React.FC<CustomTextLoaderProps> = ({
  texts,
  className,
}) => {
  const [textIndex, setTextIndex] = useState(0); // State to track the current text index
  const [ellipsis, setEllipsis] = useState("."); // State to track the ellipsis

  useEffect(() => {
    const interval = setInterval(() => {
      setTextIndex((prevIndex) => (prevIndex + 1) % texts.length); // Cycle through texts
    }, 2400);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [texts]);

  useEffect(() => {
    const interval = setInterval(() => {
      setEllipsis((prevEllipsis) =>
        prevEllipsis.length < 3 ? prevEllipsis + "." : "."
      );
    }, 800);

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  return (
    <div className={className}>
      {texts[textIndex]}
      {ellipsis}
    </div>
  );
};

export default CustomTextLoader;
