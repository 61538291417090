import { useToast } from "@/shadcn/hooks/use-toast";
import { StoredDocument } from "@/types";
import {
  addDocumentToQueue,
  clearStaleQueue,
  getDocumentQueue,
  isDocumentInQueue,
  syncDocuments,
} from "@/utils/documentLoader.util";
import { compressAndConvert } from "@/utils/fileProcessing.util";
import React, { useContext, useEffect, useReducer } from "react";
import {
  InfoCaptureContext,
  infoCaptureReducer,
  initialState,
} from "./InfoCaptureContext";
import { NetworkStatusContext } from "./NetworkStatusContext";

interface InfoCaptureProviderProps {
  children: React.ReactNode;
}

const InfoCaptureProvider: React.FC<InfoCaptureProviderProps> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(infoCaptureReducer, initialState);
  const { toast } = useToast();

  const { isOnline } = useContext(NetworkStatusContext);

  useEffect(() => {
    const processFiles = async () => {
      if (state.files && Object.keys(state.files).length > 0) {
        // loop and log
        for (const key in state.files) {
          console.log(
            `File ${key}: ${state.files[key].name}, file type: ${state.files[key].type}`
          );
          // Check if document is already in the queue
          const docInQueue = await isDocumentInQueue(key);
          if (docInQueue) {
            console.log("Document already in queue");
          } else {
            await processFile(key, state.files[key].type, state.files[key]);
          }
        }
        toast({
          title: "Success",
          description: "Documents added to queue for processing.",
          duration: 3000,
        });
        dispatch({ type: "SET_FILES", payload: {} });
      }
    };

    processFiles();
  }, [state.files]);

  // Check queue length every 2.5 seconds and set it in state
  useEffect(() => {
    const interval = setInterval(async () => {
      const queue = await getDocumentQueue();
      dispatch({ type: "SET_QUEUE_LENGTH", payload: queue.length });
    }, 2500);

    return () => clearInterval(interval);
  }, []);

  // Sync Documents (upload pending documents) every 5 seconds
  useEffect(() => {
    console.log(state.processingBatch, "is true in syncDocs");
    const interval = setInterval(async () => {
      if (isOnline && !state.processingBatch) {
        console.log("Online in InfoCaptureProvider");

        try {
          const queue = await getDocumentQueue();
          if (queue.length === 0) {
            console.log("No documents to synchronize.");
            return;
          }
          await syncDocuments();
        } catch (error) {
          console.error("Error synchronizing documents:", error);
        }
      } else {
        console.log("Offline or processing batch in InfoCaptureProvider");
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [isOnline, state.processingBatch]);

  // Listen for changes in processingBatch and reset it after 5 seconds
  useEffect(() => {
    if (state.processingBatch) {
      console.log("processingBatch is true");
      const timeout = setTimeout(() => {
        dispatch({ type: "SET_PROCESSING_BATCH", payload: false });
      }, 5000);

      return () => clearTimeout(timeout);
    }
  }, [state.processingBatch]);

  const processFile = async (id: string, fileType: string, file: File) => {
    switch (fileType) {
      case "image/jpeg":
        console.log("File type: jpeg");

        await handleImageProcessing(id, file);
        break;
      case "image/jpg":
        await handleImageProcessing(id, file);
        break;
      case "image/webp":
        await handleImageProcessing(id, file);
        break;
      case "image/png":
        await handleImageProcessing(id, file);
        break;
      case "application/pdf":
        console.log("File type: pdf");
        break;
      default:
        console.log("File type not supported");
        toast({
          title: "Error",
          description: "File type not supported: " + fileType,
          duration: 3000,
        });
        break;
    }
  };

  const handleImageProcessing = async (id: string, file: File) => {
    const result = await compressAndConvert(file);
    if (!result) {
      toast({
        title: "Error",
        description: "Failed to compress and convert image for: " + file.name,
        duration: 3000,
      });
      return;
    }

    const document: StoredDocument = {
      id: id,
      uuidGroup: state.groupID,
      status: "pending",
      documentB64: result,
      documentName: file.name,
      functionName: "uploadDocument",
    };

    const addDoc = await addDocumentToQueue(document);
    if (!addDoc) {
      console.error("Failed to add document to queue");
    } else {
      console.log("Document added to queue successfully!!!");
    }
  };

  const clearStale = async () => {
    console.log("Cleared stale queue");
    await clearStaleQueue();
    dispatch({ type: "SET_STALE", payload: false });
  };

  return (
    <InfoCaptureContext.Provider value={{ state, dispatch, clearStale }}>
      {children}
    </InfoCaptureContext.Provider>
  );
};

export { InfoCaptureContext };
export default InfoCaptureProvider;
