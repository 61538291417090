import ErrorBoundary from "@/components/ErrorBoundary/errorBoundary.component";
import { Toaster } from "@/shadcn/components/ui/toaster";
import React from "react";
import Footer from "./footer.component";

type BasePageProps = {
  children: React.ReactNode;
};

const BasePage: React.FC<BasePageProps> = ({ children }) => {
  return (
    <div className="h-svh flex flex-col">
      <div className="flex flex-1 overflow-auto">
        <div className="flex flex-col w-full">
          <ErrorBoundary>
            <div className="p-4 flex-grow">{children}</div>
            {/* Footer */}
            <Footer />
          </ErrorBoundary>
        </div>
      </div>
      <Toaster />
    </div>
  );
};
export default BasePage;
