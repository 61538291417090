import { sendSubmitForm } from "@/models/form.model";
import { Button } from "@/shadcn/components/ui/button";
import { Checkbox } from "@/shadcn/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/shadcn/components/ui/form";
import { Input } from "@/shadcn/components/ui/input";
import { useToast } from "@/shadcn/hooks/use-toast";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { z } from "zod";
import { CaptureInfo, captureInfoSchema } from "../../types";

type InfoCaptureProps = {
  formData: CaptureInfo | null;
};

const InfoCapture: React.FC<InfoCaptureProps> = ({ formData }) => {
  const nav = useNavigate();
  const { toast } = useToast();
  const [fState, setFState] = useState<{
    loading: boolean;
    error: string;
    data: CaptureInfo | null;
    input: CaptureInfo | null;
  }>({
    loading: false,
    error: "",
    data: formData,
    input: formData,
  });

  // Fix the onSubmit function to properly handle form submission and state updates
  async function onSubmit(data: CaptureInfo) {
    setFState((prevState) => ({ ...prevState, loading: true, error: "" }));
    try {
      const res = await sendSubmitForm(data);
      if (res.rStatus === "error") {
        setFState((prevState) => ({
          ...prevState,
          error: res.error,
          loading: false,
        }));
        return;
      }
      setFState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      toast({
        title: "Success",
        description: "Form submitted successfully",
        duration: 3000,
      });
      // navigate to the next page
      nav("/all/forms");
    } catch (error) {
      setFState((prevState) => ({
        ...prevState,
        error: "Submission failed",
        loading: false,
      }));
    }
  }

  const form = useForm<z.infer<typeof captureInfoSchema>>({
    resolver: zodResolver(captureInfoSchema),
    defaultValues: formData || undefined,
  });

  return (
    <div className="w-full h-full p-5 max-w-full sm:max-w-full md:max-w-[75%] lg:max-w-[50%]">
      {fState.error && (
        <div className="error text-red-600 font-bold mb-3">{fState.error}</div>
      )}
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="grid gap-4 md:grid-cols-2"
        >
          <FormField
            name="name"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <FormLabel htmlFor="name">Name</FormLabel>
                  </div>
                  <div>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    {form.formState.errors.name && (
                      <FormMessage>
                        {form.formState.errors.name.message}
                      </FormMessage>
                    )}
                  </div>
                </div>
              </FormItem>
            )}
          />
          <FormField
            name="surname"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <FormLabel htmlFor="surname">Surname</FormLabel>
                  </div>
                  <div>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    {form.formState.errors.surname && (
                      <FormMessage>
                        {form.formState.errors.surname.message}
                      </FormMessage>
                    )}
                  </div>
                </div>
              </FormItem>
            )}
          />
          <FormField
            name="nationality"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <FormLabel htmlFor="nationality">Nationality</FormLabel>
                  </div>
                  <div>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    {form.formState.errors.nationality && (
                      <FormMessage>
                        {form.formState.errors.nationality.message}
                      </FormMessage>
                    )}
                  </div>
                </div>
              </FormItem>
            )}
          />
          <FormField
            name="id_number"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <FormLabel htmlFor="id_number">ID Number</FormLabel>
                  </div>
                  <div>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    {form.formState.errors.id_number && (
                      <FormMessage>
                        {form.formState.errors.id_number.message}
                      </FormMessage>
                    )}
                  </div>
                </div>
              </FormItem>
            )}
          />
          <FormField
            name="job_title"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <FormLabel htmlFor="job_title">Job Title</FormLabel>
                  </div>
                  <div>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    {form.formState.errors.job_title && (
                      <FormMessage>
                        {form.formState.errors.job_title.message}
                      </FormMessage>
                    )}
                  </div>
                </div>
              </FormItem>
            )}
          />
          <FormField
            name="skills_level"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <FormLabel htmlFor="skills_level">Skills Level</FormLabel>
                  </div>
                  <div>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    {form.formState.errors.skills_level && (
                      <FormMessage>
                        {form.formState.errors.skills_level.message}
                      </FormMessage>
                    )}
                  </div>
                </div>
              </FormItem>
            )}
          />
          <FormField
            name="address"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <FormLabel htmlFor="address">Address</FormLabel>
                  </div>
                  <div>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    {form.formState.errors.address && (
                      <FormMessage>
                        {form.formState.errors.address.message}
                      </FormMessage>
                    )}
                  </div>
                </div>
              </FormItem>
            )}
          />
          <FormField
            name="date_of_birth"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <FormLabel htmlFor="date_of_birth">Date of Birth</FormLabel>
                  </div>
                  <div>
                    <FormControl>
                      <Input {...field} type="date" />
                    </FormControl>
                    {form.formState.errors.date_of_birth && (
                      <FormMessage>
                        {form.formState.errors.date_of_birth.message}
                      </FormMessage>
                    )}
                  </div>
                </div>
              </FormItem>
            )}
          />
          <FormField
            name="rsa_based"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <div className="grid grid-cols-4 items-center">
                  <FormLabel htmlFor="rsa_based" className="col-span-3">
                    RSA Based
                  </FormLabel>
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      className="shadow-none justify-self-end !w-10 !h-10"
                    />
                  </FormControl>
                  {form.formState.errors.rsa_based && (
                    <FormMessage className="col-span-4">
                      {form.formState.errors.rsa_based.message}
                    </FormMessage>
                  )}
                </div>
              </FormItem>
            )}
          />
          <FormField
            name="citizenship"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <div className="grid grid-cols-4 items-center">
                  <FormLabel htmlFor="citizenship" className="col-span-3">
                    Citizenship
                  </FormLabel>
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      className="shadow-none justify-self-end !w-10 !h-10"
                    />
                  </FormControl>
                  {form.formState.errors.citizenship && (
                    <FormMessage className="col-span-4">
                      {form.formState.errors.citizenship.message}
                    </FormMessage>
                  )}
                </div>
              </FormItem>
            )}
          />
          <FormField
            name="bp"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <div className="grid grid-cols-4 items-center">
                  <FormLabel htmlFor="bp" className="col-span-3">
                    Black Person
                  </FormLabel>
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      className="shadow-none justify-self-end !w-10 !h-10"
                    />
                  </FormControl>
                  {form.formState.errors.bp && (
                    <FormMessage className="col-span-4">
                      {form.formState.errors.bp.message}
                    </FormMessage>
                  )}
                </div>
              </FormItem>
            )}
          />
          <FormField
            name="local_communities"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <div className="grid grid-cols-4 items-center">
                  <FormLabel htmlFor="local_communities" className="col-span-3">
                    Local Communities
                  </FormLabel>
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      className="shadow-none justify-self-end !w-10 !h-10"
                    />
                  </FormControl>
                  {form.formState.errors.local_communities && (
                    <FormMessage className="col-span-4">
                      {form.formState.errors.local_communities.message}
                    </FormMessage>
                  )}
                </div>
              </FormItem>
            )}
          />
          <FormField
            name="youth"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <div className="grid grid-cols-4 items-center">
                  <FormLabel htmlFor="youth" className="col-span-3">
                    Youth
                  </FormLabel>
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      className="shadow-none justify-self-end !w-10 !h-10"
                    />
                  </FormControl>
                  {form.formState.errors.youth && (
                    <FormMessage className="col-span-4">
                      {form.formState.errors.youth.message}
                    </FormMessage>
                  )}
                </div>
              </FormItem>
            )}
          />
          <FormField
            name="disability"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <div className="grid grid-cols-4 items-center">
                  <FormLabel htmlFor="disability" className="col-span-3">
                    Disability
                  </FormLabel>
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      className="shadow-none justify-self-end !w-10 !h-10"
                    />
                  </FormControl>
                  {form.formState.errors.disability && (
                    <FormMessage className="col-span-4">
                      {form.formState.errors.disability.message}
                    </FormMessage>
                  )}
                </div>
              </FormItem>
            )}
          />
          <FormField
            name="women"
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <div className="grid grid-cols-4 items-center">
                  <FormLabel htmlFor="women" className="col-span-3">
                    Women
                  </FormLabel>
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                      className="shadow-none justify-self-end !w-10 !h-10"
                    />
                  </FormControl>
                  {form.formState.errors.women && (
                    <FormMessage className="col-span-4">
                      {form.formState.errors.women.message}
                    </FormMessage>
                  )}
                </div>
              </FormItem>
            )}
          />
          <Button
            type="submit"
            className="md:col-span-2"
            disabled={fState.loading}
          >
            Submit
          </Button>
        </form>
      </Form>
    </div>
  );
};

export default InfoCapture;
