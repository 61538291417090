import { fetchForms } from "@/models/documents.model";
import { DocList } from "@/types";
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import React, { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router";
import CustomTextLoader from "../CustomTextLoader/customTextLoader.componnet";
import { DataTable } from "../DataTable/dataTable.component";

type AllFormsListProps = {};

const AllFormsList: React.FC<AllFormsListProps> = () => {
  const [forms, setForms] = useState<DocList[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fakeData: DocList[] = [
    {
      id: 1,
      uuid: "123",
      created_at: "2021-09-01",
    },
    {
      id: 2,
      uuid: "456",
      created_at: "2021-09-02",
    },
    {
      id: 3,
      uuid: "789",
      created_at: "2021-09-03",
    },
  ];

  const navigate = useNavigate();

  const columns: ColumnDef<DocList>[] = [
    {
      id: "uuid",
      header: "UUID",
      accessorKey: "uuid",
      cell: ({ row }) => (
        <div className="flex flex-row justify-start w-full">
          {row.original.uuid.split("-")[0]}
        </div>
      ),
    },
    {
      id: "created_date",
      header: "Created Date",
      accessorKey: "created_at",
      cell: ({ row }) => {
        const formattedDate = format(
          new Date(row.original.created_at),
          "d MMMM yyyy"
        );
        return (
          <div className="flex flex-row justify-start w-full">
            {formattedDate}
          </div>
        );
      },
    },
    {
      id: "created_time",
      header: "Created Time",
      accessorKey: "created_at",
      cell: ({ row }) => {
        const formattedTime = format(
          new Date(row.original.created_at),
          "HH:mm"
        );
        return (
          <div className="flex flex-row justify-start w-full">
            {formattedTime}
          </div>
        );
      },
    },
    // {
    //   id: "resume_session",
    //   header: () => <div>Resume</div>,
    //   cell: ({ row }) => (
    //     <div className="flex flex-row w-full">
    //       <button
    //         onClick={() => navigate(`/home/${row.original.uuid}`)}
    //         className="text-blue-500 underline "
    //       >
    //         Resume Session
    //       </button>
    //     </div>
    //   ),
    // },

    {
      id: "actions",
      header: () => <div className="text-right">Actions</div>,
      cell: ({ row }) => (
        <div className="flex flex-row justify-end w-full">
          <button
            onClick={() => navigate(`/form/${row.original.uuid}`)}
            className="text-blue-500 underline text-right"
          >
            View
          </button>
        </div>
      ),
    },
  ];

  useLayoutEffect(() => {
    console.log("triggered");
    async function fetchFormsRes() {
      setLoading(true);
      setError("");
      const res = await fetchForms();
      setLoading(false);

      if (res.rStatus === "error") {
        setError(res.error);
        return;
      }

      setForms(
        res.rData
          // latest first - created_at is a string
          .sort((a, b) => {
            return (
              new Date(b.created_at).getTime() -
              new Date(a.created_at).getTime()
            );
          })
      );
      // setForms(fakeData);
    }

    fetchFormsRes();
  }, []);

  return (
    <>
      <h1 className="text-3xl font-bold text-center text-primary mt-3">
        Forms
      </h1>
      <div className="flex-grow flex items-center flex-col justify-start w-full p-5 overflow-auto">
        {loading ? (
          <CustomTextLoader
            texts={["Collecting data", "Sorting data", "Generating report"]}
          />
        ) : error ? (
          <div className="text-red-500">Error: {error}</div>
        ) : forms.length === 0 ? (
          <div>No forms available</div>
        ) : (
          <div className="w-full max-h-[500px]">
            <DataTable columns={columns} data={forms} />
          </div>
        )}
      </div>
    </>
  );
};

export default AllFormsList;
