import "@/App.css";
import LoginPage from "@/pages/login.page";
import { titleUpdate } from "@/utils/utils";
import { useLayoutEffect } from "react";
import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router";
import PWAPrompt from "./components/PWAPrompt/PWAPrompt.component";
import { ROUTE_HOME } from "./constants/routes.constants";
import { NetworkStatusProvider } from "./contexts/NetworkStatusProvider";
import AllForms from "./pages/all.forms.page";
import FormPage from "./pages/form.page";
import HomePage from "./pages/home.page";
import PageNotFound from "./pages/pageNotFound.page";
import ProtectedRoute from "./services/ProtectedRoute";
import { ToastProvider } from "./shadcn/components/ui/toast";

function App() {
  const TitleUpdater = ({ title }: { title?: string }) => {
    // Updates the page title based on the current route
    useLayoutEffect(() => {
      titleUpdate(title);
    }, [title]);
    return null;
  };
  return (
    <div className="App h-svh w-svw overflow-hidden">
      <ToastProvider>
        <NetworkStatusProvider>
          <Router>
            <PWAPrompt />
            <Routes>
              <Route
                path="login"
                element={
                  <>
                    <TitleUpdater title="Login" />
                    <LoginPage />
                  </>
                }
              />
              <Route element={<ProtectedRoute />}>
                <Route
                  path="/home/:formID?"
                  element={
                    <>
                      <TitleUpdater title="Home" />
                      <HomePage />
                    </>
                  }
                />
                <Route
                  path="/all/forms"
                  element={
                    <>
                      <TitleUpdater title="All Forms" />
                      <AllForms />
                    </>
                  }
                />
                <Route
                  path="/form/:uuid"
                  element={
                    <>
                      <TitleUpdater title="Form" />
                      <FormPage />
                    </>
                  }
                />
                <Route path="*" element={<PageNotFound />} />
              </Route>
              <Route path="/" element={<Navigate to={ROUTE_HOME} replace />} />
            </Routes>
          </Router>
        </NetworkStatusProvider>
      </ToastProvider>
    </div>
  );
}

export default App;
