import { ROUTE_LOGIN_OUT } from "@/constants/routes.constants";
import { InfoCaptureContext } from "@/contexts/InfoCaptureContext";
import { UserContext } from "@/contexts/UserContext/userContext";
import { Button } from "@/shadcn/components/ui/button";
import { LogOut, Wifi, WifiOff } from "lucide-react";
import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid"; // Import uuid

import { useLocation, useNavigate } from "react-router";
import { NetworkStatusContext } from "../contexts/NetworkStatusContext";

type FooterProps = {
  // TODO: add props here
};

const Footer: React.FC<FooterProps> = (props) => {
  const { user } = useContext(UserContext);
  const { isOnline } = useContext(NetworkStatusContext);
  const { dispatch } = useContext(InfoCaptureContext);

  const nav = useNavigate();
  const location = useLocation();

  function logout() {
    window.location.href = ROUTE_LOGIN_OUT;
  }

  return (
    <footer className="flex justify-between items-center p-3 b bg-neutral-300">
      <div>
        {isOnline ? (
          <Wifi className="w-4 h-4" />
        ) : (
          <WifiOff className="w-4 h-4 !text-red-600" />
        )}
      </div>
      <div className="flex gap-2">
        {user.admin ? (
          <>{/* <Button>Reports</Button> */}</>
        ) : (
          <>
            {location.pathname === "/home" ? (
              <Button
                onClick={() => {
                  console.log("clicked");
                  const groupID = uuidv4();
                  dispatch({ type: "SET_GROUPID", payload: groupID });
                }}
              >
                New Session
              </Button>
            ) : (
              <Button
                onClick={() => {
                  nav("/home");
                }}
              >
                Upload
              </Button>
            )}

            <Button
              onClick={() => {
                nav("/all/forms");
              }}
            >
              Forms
            </Button>
          </>
        )}
      </div>
      <div>
        <Button
          variant={"ghost"}
          size={"icon"}
          onClick={logout}
          className="hover:text-white"
        >
          <LogOut />
        </Button>
      </div>
    </footer>
  );
};

export default Footer;
