import LoginBackgroundImage from "@/assets/images/pexels-singkham-178541-1108572.webp";
import Login from "@/components/Login/login.component";
import { removeLocStoreIsLoggedIn } from "@/utils/localAuth.util";
import React, { useEffect, useState } from "react";

type LoginPageProps = {
  // TODO: add props here
};

const LoginPage: React.FC<LoginPageProps> = (props) => {
  useEffect(() => {
    removeLocStoreIsLoggedIn();
  }, []);

  const [buildNumber, setBuildNumber] = useState<string>("");
  useEffect(() => {
    // Fetch the build number from content of div with id "build-number"
    const buildNumber =
      document.getElementById("build-number")?.textContent || "";
    setBuildNumber(buildNumber);
  }, []);

  return (
    <div>
      <div className="flex justify-center align-middle h-svh items-center bg-neutral-900">
        <img
          src={LoginBackgroundImage}
          alt=""
          className="w-full h-full object-cover fixed top-0 left-0 z-0 opacity-30"
        />
        <Login />
        <div className="absolute bottom-2 right-2 text-neutral-300">
          Build Number : {buildNumber}
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
