import { ROUTE_LOGIN_OUT } from "@/constants/routes.constants";
import { NetworkStatusContext } from "@/contexts/NetworkStatusContext";
import UserProvider from "@/contexts/UserContext/userProvider";
import { sendLoginRequest } from "@/models/auth.model";
import {
  decodeLoginDetails,
  getLocStoreIsLoggedIn,
  getLocStoreUserDetails,
  removeLocStoreUserDetails,
} from "@/utils/localAuth.util";
import { getTokensLocalStorage } from "@/utils/tokenHandler.util";
import React, { useContext, useLayoutEffect, useState } from "react";
import { Navigate, Outlet } from "react-router";

export const ProtectedRoute: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCheckComplete, setIsCheckComplete] = useState(false);
  const { isOnline } = useContext(NetworkStatusContext);

  async function checkUserLoginStatus() {
    console.log("Checking user tokens...");
    const lsIsLoggedIn = getLocStoreIsLoggedIn();
    if (!lsIsLoggedIn || lsIsLoggedIn !== "true") {
      setIsLoggedIn(false);
      setIsCheckComplete(true);
      console.log("User not logged in");
      return;
    }

    const userDetails = getLocStoreUserDetails();
    if (!userDetails) {
      setIsLoggedIn(false);
      setIsCheckComplete(true);
      console.log("No user details found");
      return;
    }

    const decodedUser = decodeLoginDetails(userDetails);

    if (
      !decodedUser ||
      decodedUser.username === "" ||
      decodedUser.password === ""
    ) {
      setIsLoggedIn(false);
      setIsCheckComplete(true);
      removeLocStoreUserDetails();
      console.log("Invalid user details");
      return;
    }

    const token = await getTokensLocalStorage();
    if (!token || !token.refreshToken) {
      setIsLoggedIn(false);
      setIsCheckComplete(true);
      console.log("No refreshToken found");
      return;
    }

    setIsLoggedIn(true);
    setIsCheckComplete(true);
  }

  async function checkUserTokens() {
    console.log("Checking user tokens...");
    const userDetails = getLocStoreUserDetails();

    if (!userDetails) {
      console.log("No user details found");
      return;
    }

    const decodedUser = decodeLoginDetails(userDetails);

    if (
      !decodedUser ||
      decodedUser.username === "" ||
      decodedUser.password === ""
    ) {
      console.log("Invalid user details");
      return;
    }

    const loginRes = await sendLoginRequest(
      decodedUser.username,
      decodedUser.password
    );
    console.log(loginRes);
  }

  useLayoutEffect(() => {
    checkUserLoginStatus();
  }, []);

  useLayoutEffect(() => {
    // if online check for new tokens every 5 minutes
    if (isOnline) {
      const interval = setInterval(() => {
        checkUserTokens();
      }, 5 * 60 * 1000);

      // Cleanup function to clear the interval when going offline
      return () => clearInterval(interval);
    }
  }, [isOnline]);

  return !isCheckComplete ? // If the check is not complete, show a loading spinner
  null : isLoggedIn ? (
    <UserProvider>
      <Outlet />
    </UserProvider>
  ) : (
    <Navigate to={ROUTE_LOGIN_OUT} />
  );
};

export default ProtectedRoute;
