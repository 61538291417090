import { Button } from "@/shadcn/components/ui/button";
import { fileTypeFromBlob } from "file-type";
import React, { useCallback, useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid"; // Import uuid
import { InfoCaptureContext } from "../../contexts/InfoCaptureContext";

type FileInputProps = {};

const FileInput: React.FC<FileInputProps> = () => {
  const [selectedFiles, setSelectedFiles] = useState<{ [key: string]: File }>(
    {}
  );
  const { dispatch } = useContext(InfoCaptureContext);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const newFiles = { ...selectedFiles };

      for (const file of acceptedFiles) {
        const fileType = await fileTypeFromBlob(file);

        if (!fileType) {
          console.error(`Unsupported file type for file: ${file.name}`);
          continue;
        }

        console.log(
          `Detected file type: ${fileType.ext}, MIME: ${fileType.mime}`
        );

        if (["png", "jpeg", "jpg", "webp"].includes(fileType.ext)) {
          console.log("File type:", fileType.ext);
          const id = uuidv4();
          newFiles[id] = file;
        } else {
          console.error(`File type not supported: ${fileType.ext}`);
        }
      }
      setSelectedFiles(newFiles);
    },
    [selectedFiles]
  );

  const removeFile = (id: string) => {
    const newFiles = { ...selectedFiles };
    delete newFiles[id];
    setSelectedFiles(newFiles);
  };

  const handleSubmit = () => {
    console.log("selectedFiles", selectedFiles);
    // return;
    dispatch({ type: "SET_FILES", payload: selectedFiles });
    dispatch({ type: "SET_PROCESSING_BATCH", payload: true });

    setSelectedFiles({});
  };

  // Dropzone config
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { "image/*": [".png", ".jpeg", ".jpg"] },
    noClick: false,
    multiple: true,
    maxFiles: 5,
  });

  return (
    <div className="w-full flex flex-col items-center justify-center p-5 ">
      <div
        {...getRootProps()}
        style={{
          border: "2px dashed #ccc",
          borderRadius: "8px",
          padding: "20px",
          textAlign: "center",
        }}
        className="cursor-pointer max-w-[500px] w-[90%]"
      >
        <input {...getInputProps()} className="max-w-[10px]" />
        {Object.keys(selectedFiles).length > 0 ? (
          <div className="p-1 flex flex-col gap-1 items-center w-full ">
            {Object.entries(selectedFiles).map(([id, file]) => (
              <div
                key={id}
                className="flex items-center gap-2 w-full justify-between"
              >
                <p className="truncate max-w-[90%]">{file.name}</p>
                <Button
                  onClick={(e) => {
                    removeFile(id);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  className="max-w-[10%]"
                  variant={"ghost"}
                  size={"sm"}
                >
                  ✕
                </Button>
              </div>
            ))}
          </div>
        ) : (
          <p>Drag & drop files here, or click to select files</p>
        )}
        <div className="flex flex-col items-center">
          <small>(Supported types: PNG, JPEG, JPG)</small>
          <small>(Max files: 5)</small>
          {/* Clear all button */}
          {Object.keys(selectedFiles).length > 0 && (
            <div className="flex flex-col gap-1 items-center mt-2 w-full">
              <Button
                onClick={(e) => {
                  setSelectedFiles({});
                  e.preventDefault();
                  e.stopPropagation();
                }}
                variant={"ghost"}
                size={"sm"}
                className="max-w-fit"
              >
                Clear all
              </Button>
              <Button
                onClick={(e) => {
                  handleSubmit();
                  e.preventDefault();
                  e.stopPropagation();
                }}
                variant={"default"}
                // size={"sm"}
                className="w-full"
              >
                Submit
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileInput;
